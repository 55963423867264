import React, { useState, useEffect } from 'react';
import HeroSection from './HeroSection';
import BrandSection from './BrandsSection';
import Footer from '../../CommonComponents/Footer';
import { Helmet } from 'react-helmet';
import BlackSection from './BlackSection';
import BasicModal from '../../CommonComponents/BasicModal';
import Header from '../../CommonComponents/Header';
import ReactLoading from 'react-loading';
import mixpanel from 'mixpanel-browser';
import ProductsSection from './ProductsSection'
import VideoSection from './VideoSection'
import ContactModal from '../../CommonComponents/ContactModal';
import qs from 'qs';
import logo from '../../assets/icons/addsomeIconBlack.png'
import InfoCards from '../ArchitectPage/InfoCards'
import FindMoreButton from '../../CommonComponents/FindMoreButton';
import {useTranslation} from "react-i18next";

const LandingPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [brandsNo, setBrandsNo] = useState(0)
  const [products, setProductsNo] = useState(0)
  const [files3dNo, set3dFilesNo] = useState(0)
  const [openContactModal, setOpenContactModal] = useState(false);

  const { t } = useTranslation('common');
  
  useEffect(() => {
    mixpanel.init('1649b8db3bc1a1cf454dc144a3959e5a', { debug: true });

    if(window.location.search) {
      const email = qs.parse(window.location.search, { ignoreQueryPrefix: true }).identity

      if(email) {
        mixpanel.identify(email)
      }
    }
    mixpanel.track('Page viewed: Landing page', {});
  }, [])

  useEffect(() => {

    fetch('https://api.addsome.io/metrics/all', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
      },
    })
      .then(response => response.json())
      .then(data => {
        setBrandsNo(data.brands)
        setProductsNo(data.products)
        set3dFilesNo(data.filesWith3D)
      })
      .catch((error) => {
        console.log("Error", error)
        setBrandsNo(185)
        setProductsNo(58514)
        set3dFilesNo(274472)
      });
  }, [])

  return (
    <>
      {brandsNo > 0 && products > 0 && files3dNo > 0 ?
        <div>
          {openModal &&
            <BasicModal closeModal={() => { setOpenModal(false); }} />
          }
          {openContactModal &&
            <ContactModal closeModal={() => { setOpenContactModal(false); }} />
          }
          <Header color="rgba(255, 255, 255, 255)" text="Login" openModal={() => window.location.href = 'https://drive.addsome.io/login'} />
          <Helmet>
            <title>{'Addsome library - thousands of 3D furniture files'}</title>
          </Helmet>
          <HeroSection brandsNo={brandsNo} productsNo={products} files3dNo={files3dNo} />
          <BlackSection number='1' />
          <VideoSection />
          <div className='buttonSection'>
            <a href='https://drive.addsome.io/register'>
              <FindMoreButton text={t('planCards.getStarted')} color="#fff"/>
            </a>
          </div>
          <ProductsSection />
          <div className='cardsInfoWrapper'>
            <InfoCards />
          </div>
          <div className='architectsButton'>
            <FindMoreButton text={t('global.findOutMore')} color="rgba(255, 171, 71, 1)" page="/architects" />
          </div>
          <BrandSection />
          <BlackSection number='2' />
          <Footer setOpenContactModal={setOpenContactModal} />
        </div>
        :
        <div className='centerLoader'>
          <img src={logo} />
        </div>
      }
    </>
  );
};

export default LandingPage;
