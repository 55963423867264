import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from './Pages/NotFound';
import LandingPage from './Pages/LandingPage';
import ArchitectPage from './Pages/ArchitectPage';
import BrandPage from './Pages/BrandPage';
import "./assets/fonts/Arkitech Medium.ttf"
import { IntlProvider } from 'react-intl'
import { useTranslation } from 'react-i18next';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

function App() {
  const { t, i18n } = useTranslation();
  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      <header className="App-header">
      <I18nextProvider i18n={i18next}>
          <Router>
            <Routes>
              <Route path="*" exact element={<NotFound />} status={404} />
              <Route exact path={"/"} element={<LandingPage />} />
              <Route exact path={"/architects"} element={<ArchitectPage />} />
              <Route exact path={"/brands"} element={<BrandPage />} />
            </Routes>
          </Router>
        </I18nextProvider>
      </header>
    </div>
  );
}

export default App;
