
import addsomeLogo from "./logos/addsomeLogo.svg"
import addsomeLogoOrange from "./logos/orangeLogo.svg"
import addsomeLogoPurple from "./logos/purpleLogo.svg"
import addsomeLogoLandingPage from "./logos/addsomeLogoLandingPage.svg"
import firstRow from "./logos/firstRowSVG.svg"
import longRightArrow from "./icons/longRightArrow.svg"


const theme = {
  colors: {
    landingPageColor: "rgba(27, 27, 27, 1)",
  },
  sizes: {
    title: "3.89vw",
  },
  weight: {
    normal: "500",
    medium: "600",
    bold: "700",
    bolder: "1000",
    light: "300",
    header: "400",
  },
  fonts: {
  },
  images: {
    addsomeLogo,
    addsomeLogoOrange,
    addsomeLogoPurple,
    longRightArrow,
    addsomeLogoLandingPage,
    firstRow,
  },
  device: {
    mobile: `(max-width: 768px)`,
    tablet: `(min-width: 769px) and (orientation: portrait) `,
    desktop: `(min-width: 769px)`,
  },
};

export default theme;
