import React, { useEffect, useState } from 'react';
import BasicModal from '../../CommonComponents/BasicModal';
import FindMoreButton from '../../CommonComponents/FindMoreButton';
import './style/style.modules.scss';
import theme from "../../assets";
import useIntersectionObserver from '../../CommonComponents/hooks';
import chart1 from "../../assets/images/chartbrands.svg"
import chart2 from "../../assets/images/chartproducts.svg"
import chart3 from "../../assets/images/chart3ds.svg"
import {useTranslation} from "react-i18next";
import { Button } from '@mui/material'

const HeroSection = ({ brandsNo, productsNo, files3dNo }) => {
  const [openModal, setOpenModal] = useState(false);
  const [changeSvg, setChangeSvg] = useState(false);
  const [repeat, setRepeat] = useState(0);

  const ref = React.useRef();

  const { t } = useTranslation('common');

  let isVisible;
  isVisible = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (isVisible === true && repeat < 3) {
      setRepeat((prevValue) => prevValue + 1);
      // setTimeout(() => {
      //   setChangeSvg(true);
      // }, 800);
    }


  }, [isVisible]);

  return (
    <>
      {openModal &&
        <BasicModal closeModal={() => { setOpenModal(false); }} />
      }
      <div className="heroWrapperBrandLandingPage" id="backgroundWrapperId" ref={ref}>
        <img
          src={require("../../assets/images/hero-landing.jpg")}
          alt='Hero landing page'
          className={`backgroundImage ${isVisible && repeat < 2 && "backgroundImageAnimation"}`} />
        <div className='textArea'>
          <div className='designersTitle'>{t('global.designers')}</div>
          <div className="flexArea">
            <div className='designersDescription'>
              <h1 className='description' id="titleId">
                {t('landingPage.interior')}
              </h1>
              <ul className={`bulletPointText ${isVisible && repeat < 2 && "bulletPointTextAnimation"}`}>
                <li>{t('landingPage.access')}</li>
                <li>{t('landingPage.powerful')}</li>
                <li>{t('landingPage.top')}</li>
                <li>{t('landingPage.every')}</li>
                <li>{t('landingPage.built')}</li>
              </ul>
            </div>
            <img src={require("../../assets/images/logoLandingPage.png")} alt="Logo Addsome"></img>
          </div>
          <div className='chartArea'>
            <div>
              <img src={chart1} alt="Chart brands"></img>
              <span>
                <p className="chartNo">{brandsNo}</p>
                <p>{t('global.brands')}</p>
              </span>
            </div>
            <div>
              <img src={chart2} alt="Chart products"></img>
              <span>
                <p className="chartNo">{productsNo.toLocaleString()}</p>
                <p>{t('global.products')}</p>
              </span>
            </div>
            <div>
              <img src={chart3} alt="Chart 3ds"></img>
              <span>
                <p className="chartNo">{files3dNo}</p>
                <p>{t('global.3dFiles')}</p>
              </span>
            </div>
          </div>
          <div className='buttonSection'>
            <a href='https://drive.addsome.io/register'>
              <FindMoreButton
                text={t('planCards.getStarted')}
                color="#fff"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
