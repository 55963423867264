import React, { useState, useEffect } from 'react';
import CardAndText from '../../CommonComponents/CardAndText';
import ContactUs from '../../CommonComponents/ContactUs';
import Footer from '../../CommonComponents/Footer';
import Header from '../../CommonComponents/Header';
import HeroSection from '../../CommonComponents/HeroSection';
import "./style/style.modules.scss";
import { Helmet } from 'react-helmet';
import BasicModal from '../../CommonComponents/BasicModal';
import ContactModal from '../../CommonComponents/ContactModal';
import ReactLoading from 'react-loading';
import chart1 from "../../assets/images/chartbrands.svg"
import chart2 from "../../assets/images/chartproducts.svg"
import chart3 from "../../assets/images/chart3ds.svg"
import mixpanel from 'mixpanel-browser';
import {useTranslation} from "react-i18next";

const ArchitectPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [brandsNo, setBrandsNo] = useState(0)
  const [products, setProductsNo] = useState(0)
  const [files3dNo, set3dFilesNo] = useState(0)
  const { t } = useTranslation('common');

  useEffect(() => {
    mixpanel.init('1649b8db3bc1a1cf454dc144a3959e5a', { debug: true });
    mixpanel.track('Page viewed: Architects page', {});
  }, [])

  useEffect(() => {
    fetch('https://api.addsome.io/metrics/all', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
      },
    })
      .then(response => response.json())
      .then(data => {
        setBrandsNo(data.brands)
        setProductsNo(data.products)
        set3dFilesNo(data.filesWith3D)
      })
      .catch((error) => {
        console.log("Error", error)
      });
  }, [])

  return (
    <>
      {brandsNo > 0 && products > 0 && files3dNo > 0 ?
        <div>
          {openModal &&
            <BasicModal closeModal={() => { setOpenModal(false); }} />
          }
          {openContactModal &&
            <ContactModal closeModal={() => { setOpenContactModal(false); }} />
          }
          <Helmet>
            <title>{t('designersPage.addsomeForArchitects')}</title>
          </Helmet>
          <Header color="rgba(255, 255, 255, 255)" text="Login" openModal={() => window.location.href = 'https://drive.addsome.io/login'} />
          <div className='brandsTitle'>{t('global.designers')}</div>
          <HeroSection
            color="#C3C3C3"
            title="ADDSOME"
            brandName={t('designersPage.whereLimitless')}
            description={t('designersPage.access')}
          />
          <div className='graphicArea'>
            <div className='chartArea'>
              <div>
                <p>{t('global.brands')}</p>
                <div className="chartWrapper">
                  <img src={chart1} alt="Chart brands"></img>
                  <span>
                    <p className='charNo'>{brandsNo}</p>
                    <p>{t('global.brands')}</p>
                  </span>
                </div>
              </div>
              <div>
                <p>{t('global.products')}</p>
                <div className="chartWrapper">
                  <img src={chart2} alt="Chart products"></img>
                  <span>
                    <p className='charNo'>{products}</p>
                    <p>{t('global.products')}</p>
                  </span>
                </div>
              </div>
              <div>
                <p>{t('global.3dFiles')}</p>
                <div className="chartWrapper">
                  <img src={chart3} alt="Chart 3ds"></img>
                  <span>
                    <p className='charNo'>{files3dNo}</p>
                    <p>{t('global.3dFiles')}</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <ObjectCards brandsNo={brandsNo} products={products} files3dNo={files3dNo} /> */}
          <div className='descriptionSectionArchitects'>
            <div className="brandNameCard">{t('designersPage.interior')}</div>
            <p>
            {t('designersPage.3dImage')}
        </p>
          </div>
          <div className='advSection'>
            <CardAndText imageName="architect1.png"
              title={t('designersPage.decorate').toUpperCase()}
              titleColor="#C3C3C3"
              subtitle={t('designersPage.speed')}
              description={[t('designersPage.powerfulFilters'),
                t('designersPage.everythingInOnePlace'),
                t('designersPage.allTheFileFormats')]}
            />
            <CardAndText imageName="architect2.png"
              title={t('designersPage.expressYourIdeas').toUpperCase()}
              titleColor="#C3C3C3"
              subtitle={t('designersPage.withPrecision')}
              description={[t('designersPage.createStunningRenders'),
                t('designersPage.imageLibrary'),
                t('designersPage.everythingYouNeed')]}
              rowReverse
            />
            <CardAndText imageName="architect3.png"
              title={t('designersPage.impress').toUpperCase()}
              titleColor="#C3C3C3"
              subtitle={t('designersPage.watchThem')}
              description={[t('designersPage.bringSpacesToLife'),
                t('designersPage.createMultipleRenders'),
                t('designersPage.stayOnBudget'),
                t('designersPage.deliverTopSelling')]}
            />
          </div>
          <ContactUs buttonColor="#151515"
            openModal={() => setOpenContactModal(true)} />
          <Footer setOpenContactModal={setOpenContactModal} />
        </div>
        :
        <div className='centerLoader'>
          <ReactLoading type='spin' color='#FFFFFF' height='8%' width='8%' />
        </div>
      }
    </>
  );
};

export default ArchitectPage;
