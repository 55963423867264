import React from 'react';
import './style/style.modules.scss';
import Button from '../../CommonComponents/Button';
import {useTranslation} from "react-i18next";
import fr from '../../assets/icons/fr.png'
import gb from '../../assets/icons/gb.png'
import cookie from '../../assets/images/cookies.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';



const Footer = ({ setOpenContactModal }) => {

  const time = new Date().getTime();
  const { t, i18n } = useTranslation('common');

  return (
    <div className='footerWrapper'>
      <div className="sections">
        <div className='firstSection'>
          <img src={require("../../assets/icons/addsomeIcon.png")} alt="addsome icon" />
          <p>Copyright @ 2023 Addsome</p>
          <div className='languageSection'>
            <div 
              onClick={() => i18n.changeLanguage('fr')} 
              className={`language ${i18n.language == 'fr' ? 'languageDisabled' : ''}`}
            >
              <img src={fr}></img>
              FR
            </div>
            <div>&nbsp;|&nbsp;</div>
            <div 
              onClick={() => i18n.changeLanguage('en-GB')} 
              className={`language ${i18n.language == 'en-GB' ? 'languageDisabled' : ''}`}
            >
              <img src={gb}></img>
              EN
            </div>
          </div>
          {/* <span className='flagsWrapper'>
            <img src={require("../../assets/icons/french.png")} alt="french icon"/> <p>FR</p>
            |
            <img src={require("../../assets/icons/english.png")} alt="english icon"/> <p>EN</p>
          </span> */}
        </div>
        <div className='secondSection'>
          <div>
            <a href='https://www.facebook.com/profile.php?id=100095112354583' target='_blank'>
              <img src={require("../../assets/icons/facebook.png")} alt="facebook icon" />
            </a>
            <a href='https://www.tiktok.com/@addsome_design' target='_blank'>
              <img src={require("../../assets/icons/tik-tok.png")} alt="tikTok icon" />
            </a>
            <a href='https://www.instagram.com/addsome_edesign/' target='_blank' >
              <img src={require("../../assets/icons/instagram.png")} alt="instagram icon" />
            </a>
          </div>
          <div>
            <span onClick={() => setOpenContactModal(true)}>
              {t('footer.contact')}
            </span>
            <a href={`${i18n.language == 'fr' ? `/documents/Conditions_Generales_de_Services.pdf` : `/documents/General_Terms_and_Conditions.pdf`}`} target="_blank">
              {t('footer.terms')}
            </a>
            <a href={ `${i18n.language == 'fr' ? `/documents/Mentions_Legales.pdf` : `/documents/Legal_Mentions.pdf`}`} target="_blank">
              {t('footer.legal')}
            </a>
            <a href={`${i18n.language == 'fr' ? `/documents/Charte_de_Confidentialite.pdf` :`/documents/Privacy_Policy.pdf`}`} target="_blank">
              {t('footer.privacy')}
            </a>
            <div onClick={() => window.tarteaucitron.userInterface.openPanel() }>
              {t('footer.manageCookies')}
            </div>
          </div>
        </div>
        <span className='mobileCopyright'>
          Copyright @ 2023 Addsome
          <img onClick={ () => window.tarteaucitron.userInterface.openPanel() } src={cookie} />
        </span>
      </div>
    </div>
  );
};

export default Footer;
