import React, { useEffect, useState } from 'react';
import FindMoreButton from '../../CommonComponents/FindMoreButton';
import './style/style.modules.scss';
import theme from "../../assets";
import useIntersectionObserver from '../../CommonComponents/hooks';

const HeroSection = ({ withText = true, withButton = true, background = "rgba(27, 27, 27, 0.3)" }) => {

  const [changeSvg, setChangeSvg] = useState(false);
  const [repeat, setRepeat] = useState(0);
  // setTimeout(() => {
  //   setChangeSvg(true);
  // }, 800);
  const ref = React.useRef();

  let isVisible;
  isVisible = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (isVisible === true && repeat < 3)
      setRepeat((prevValue) => prevValue + 1);

  }, [isVisible]);


  return (
    <div className="brandsDescriptionWrapper" ref={ref}>
      <div className="backgroundRepeat"></div>\
      <div className='textAreaBrands'>
        <div className='logoSection'>
          <div className='logoRow'>
            <img src={require("../../assets/logos/logoSection/logo1.png")} alt="logo 1" />
            <img src={require('../../assets/logos/logoSection/logo2.png')} style={{ height: '3.5vh' }} alt="logo 2" />
            <img src={require('../../assets/logos/logoSection/logo3.png')} alt="logo 3" />
            <img src={require('../../assets/logos/logoSection/logo4.png')} alt="logo 4" />
            <img src={require('../../assets/logos/logoSection/logo5.png')} style={{ height: '3.5vh' }} alt="logo 5" />
            <img src={require('../../assets/logos/logoSection/logo6.png')} alt="logo 6" />
          </div>
          <div className={'logoRow'}>
            <img src={require('../../assets/logos/logoSection/logo7.png')} alt="logo 7" />
            <img src={require('../../assets/logos/logoSection/logo8.png')} style={{ height: '3.5vh' }} alt="logo 8" />
            <img src={require('../../assets/logos/logoSection/logo9.png')} style={{ height: '4.5vh' }} alt="logo 9" />
            <img src={require('../../assets/logos/logoSection/logo10.png')} style={{ height: '3.5vh' }} alt="logo 10" />
            <img src={require('../../assets/logos/logoSection/logo11.png')} alt="logo 11" />
          </div>
          <div className='logoRow'>
            <img src={require('../../assets/logos/logoSection/logo12.png')} style={{ height: '3vh' }} alt="logo 12" />
            <img src={require('../../assets/logos/logoSection/logo13.png')} alt="logo 13" />
            <img src={require('../../assets/logos/logoSection/logo14.png')} style={{ height: '4.5vh' }} alt="logo 14" />
            <img src={require('../../assets/logos/logoSection/logo15.png')} style={{ height: '4vh' }} alt="logo 15" />
            <img src={require('../../assets/logos/logoSection/logo16.png')} style={{ height: '4.5vh' }} alt="logo 16" />
            <img src={require('../../assets/logos/logoSection/logo17.png')} style={{ height: '4vh' }} alt="logo 17" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
