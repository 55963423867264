import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Loader, OrbitControls, useGLTF, PerspectiveCamera, Environment } from "@react-three/drei";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import styles from './ProductViewer.module.scss';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import * as THREE from "three";
import { FormattedMessage } from 'react-intl';
import { useAsset } from 'use-asset';
import Loading from "react-loading";
import * as crypto from 'crypto-js';

const ObjectRender = ({ productComponents, reference, stopLoader, files }) => {
  var [normalMap0, diffuseMap0, roughnessMap0, normalMap1, diffuseMap1, roughnessMap1, normalMap2, diffuseMap2, roughnessMap2,
    normalMap3, diffuseMap3, roughnessMap3, normalMap4, diffuseMap4, roughnessMap4, normalMap5, diffuseMap5, roughnessMap5] = useLoader(TextureLoader, files);

  normalMap0.wrapS = THREE.RepeatWrapping;
  normalMap0.wrapT = THREE.RepeatWrapping;
  diffuseMap0.wrapS = THREE.RepeatWrapping;
  diffuseMap0.wrapT = THREE.RepeatWrapping;
  roughnessMap0.wrapS = THREE.RepeatWrapping;
  roughnessMap0.wrapT = THREE.RepeatWrapping;

  productComponents[0].normalMap = normalMap0;
  productComponents[0].diffuseMap = diffuseMap0;
  productComponents[0].roughnessMap = roughnessMap0;

  if (1 in productComponents) {
    normalMap1.wrapS = THREE.RepeatWrapping;
    normalMap1.wrapT = THREE.RepeatWrapping;
    diffuseMap1.wrapS = THREE.RepeatWrapping;
    diffuseMap1.wrapT = THREE.RepeatWrapping;
    roughnessMap1.wrapS = THREE.RepeatWrapping;
    roughnessMap1.wrapT = THREE.RepeatWrapping;

    productComponents[1].normalMap = normalMap1;
    productComponents[1].diffuseMap = diffuseMap1;
    productComponents[1].roughnessMap = roughnessMap1;
  }

  if (2 in productComponents) {
    normalMap2.wrapS = THREE.RepeatWrapping;
    normalMap2.wrapT = THREE.RepeatWrapping;
    diffuseMap2.wrapS = THREE.RepeatWrapping;
    diffuseMap2.wrapT = THREE.RepeatWrapping;
    roughnessMap2.wrapS = THREE.RepeatWrapping;
    roughnessMap2.wrapT = THREE.RepeatWrapping;

    productComponents[2].normalMap = normalMap2;
    productComponents[2].diffuseMap = diffuseMap2;
    productComponents[2].roughnessMap = roughnessMap2;
  }

  if (3 in productComponents) {
    normalMap3.wrapS = THREE.RepeatWrapping;
    normalMap3.wrapT = THREE.RepeatWrapping;
    diffuseMap3.wrapS = THREE.RepeatWrapping;
    diffuseMap3.wrapT = THREE.RepeatWrapping;
    roughnessMap3.wrapS = THREE.RepeatWrapping;
    roughnessMap3.wrapT = THREE.RepeatWrapping;

    productComponents[3].normalMap = normalMap3;
    productComponents[3].diffuseMap = diffuseMap3;
    productComponents[3].roughnessMap = roughnessMap3;
  }

  if (4 in productComponents) {
    normalMap4.wrapS = THREE.RepeatWrapping;
    normalMap4.wrapT = THREE.RepeatWrapping;
    diffuseMap4.wrapS = THREE.RepeatWrapping;
    diffuseMap4.wrapT = THREE.RepeatWrapping;
    roughnessMap4.wrapS = THREE.RepeatWrapping;
    roughnessMap4.wrapT = THREE.RepeatWrapping;

    productComponents[4].normalMap = normalMap4;
    productComponents[4].diffuseMap = diffuseMap4;
    productComponents[4].roughnessMap = roughnessMap4;
  }

  if (5 in productComponents) {
    normalMap5.wrapS = THREE.RepeatWrapping;
    normalMap5.wrapT = THREE.RepeatWrapping;
    diffuseMap5.wrapS = THREE.RepeatWrapping;
    diffuseMap5.wrapT = THREE.RepeatWrapping;
    roughnessMap5.wrapS = THREE.RepeatWrapping;
    roughnessMap5.wrapT = THREE.RepeatWrapping;

    productComponents[5].normalMap = normalMap5;
    productComponents[5].diffuseMap = diffuseMap5;
    productComponents[5].roughnessMap = roughnessMap5;
  }

  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  // useFrame((state, delta) => { if (ref.current) return (ref.current.rotation.x += delta) })

  useEffect(() => {
    stopLoader();
  }, []);

  useEffect(() => {
    return () => {
      // Dispose of textures here
      if (normalMap0) {
        normalMap0.dispose();
        diffuseMap0.dispose();
        roughnessMap0.dispose();
        normalMap0 = null;
        diffuseMap0 = null;
        roughnessMap0 = null;
      }

      if (normalMap1) {
        normalMap1.dispose();
        diffuseMap1.dispose();
        roughnessMap1.dispose();
        normalMap1 = null;
        diffuseMap1 = null;
        roughnessMap1 = null;
      }

      if (normalMap2) {
        normalMap2.dispose();
        diffuseMap2.dispose();
        roughnessMap2.dispose();
        normalMap2 = null;
        diffuseMap2 = null;
        roughnessMap2 = null;
      }

      if (normalMap3) {
        normalMap3.dispose();
        diffuseMap3.dispose();
        roughnessMap3.dispose();
        normalMap3 = null;
        diffuseMap3 = null;
        roughnessMap3 = null;
      }

      if (normalMap4) {
        normalMap4.dispose();
        diffuseMap4.dispose();
        roughnessMap4.dispose();
        normalMap4 = null;
        diffuseMap4 = null;
        roughnessMap4 = null;
      }

      if (normalMap5) {
        normalMap5.dispose();
        diffuseMap5.dispose();
        roughnessMap5.dispose();

        normalMap5 = null;
        diffuseMap5 = null;
        roughnessMap5 = null;
      }
      // Dispose of other textures as needed
    };
  }, []);

  // useEffect(() => () => useAsset.clear(), [])

  return (
    <group
      ref={reference}
      dispose={null}
    >
      {productComponents.map((component) => (
        <mesh
          ref={ref}
          visible
          key={component.key}
          geometry={component.geometry}
          material={component.material}
          material-color={component.color}
          castShadow
          receiveShadow
          position={[0, -0.5, 0]}
          dispose={null}
        >
          <meshStandardMaterial
            map={component.diffuseMap}
            normalMap={component.normalMap}
            normalScale={new THREE.Vector2(component.normalMapStrength, component.normalMapStrength)}
            roughnessMap={component.roughnessMap}
            roughness={component.roughness}
            opacity={component.opacity}
            metalness={component.metalness}
            emissiveIntensity={component.emissiveIntensity}
            transparent={component.transparent}
            color={component.color}
            side={THREE.DoubleSide}
          />
        </mesh>
      ))}
      <mesh position={[0, -0.5, 0]} rotation={[-Math.PI / 2, 0, 0]} 
      receiveShadow 
      castShadow
      dispose={null}
      >
        <planeBufferGeometry attach="geometry" args={[10, 10, 10]} />
        <meshStandardMaterial attach="material" color={"#FFFFFF"} roughness={1} metalness={0} />
      </mesh>
    </group >
  );
};

const CanvasComponent = ({ glbFile, objectProductVariations, variationIndex, isOnView }) => {
  const secretKey = 'c6e45b8e7a1f65a76f8e9f6c4a5e3b6d';
  const [productComponentsFinal, setProductComponentsFinal] = useState([]);
  const [filesFinal, setFilesFinal] = useState([]);
  const [loader, setLoader] = useState(true);
  const mediaURLEncrypted = glbFile && glbFile.media && glbFile.media.url;
  const mediaURlDecrypted = mediaURLEncrypted && crypto.AES.decrypt(mediaURLEncrypted, secretKey).toString(crypto.enc.Utf8);
  const { nodes, materials } = useGLTF(mediaURlDecrypted, "https://www.gstatic.com/draco/versioned/decoders/1.5.6/");
  const reference = useRef<{ position: { x: number, y: number, z: number; }, rotation: { x: number, y: number, z: number; }; }>();
  const [readyToRender, setReadyToRender] = useState(false);
  const [filesChecked, setFilesChecked] = useState(false);
  const noTextureFile = 'https://storage.googleapis.com/addsome-prod/textures/default/no_texture.jpg';
  let dbMaterials = objectProductVariations[variationIndex] && objectProductVariations[variationIndex].materials;
  const createProductComponent = useCallback(
    (dbMaterials) => {
      let files: string[] = [];
      let productComponents: any[] = [];
      Object.keys(nodes).forEach(key => {
        const node = nodes[key];

        if (node.type === 'Mesh') {
          let color = "";
          let textureNormalMap = "";
          let textureDiffuseMap = "";
          let textureRoughnessMap = "";
          let type = "";
          let roughness = 1;
          let opacity = 1;
          let emissiveIntensity = 0;
          let metalness = 0;
          let transparent = false;
          let normalMapStrength = null;

          if (dbMaterials) {
            Object.keys(dbMaterials).forEach(key => {
              const dbMaterial = dbMaterials[key];

              if (dbMaterial.materialName === node.name) {
                color = dbMaterial._DiffuseColor;
                type = dbMaterial.presetGroup;

                if (dbMaterial._NormalMap !== null) {
                  textureNormalMap = dbMaterial._NormalMap;

                  if (textureNormalMap.includes('.png')) {
                    textureNormalMap = textureNormalMap.replace('.png', '');
                  }
                  if (textureNormalMap.includes('.jpg')) {
                    textureNormalMap = textureNormalMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._NormalMapStrength != '' && dbMaterial._NormalMapStrength != null) {
                  normalMapStrength = dbMaterial._NormalMapStrength;
                }

                if (dbMaterial._DiffuseMap !== null) {
                  textureDiffuseMap = dbMaterial._DiffuseMap;

                  if (textureDiffuseMap.includes('.png')) {
                    textureDiffuseMap = textureDiffuseMap.replace('.png', '');
                  }
                  if (textureDiffuseMap.includes('.jpg')) {
                    textureDiffuseMap = textureDiffuseMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._SmoothnessMap !== null) {
                  textureRoughnessMap = dbMaterial._SmoothnessMap;
                  if (textureRoughnessMap.includes('.png')) {
                    textureRoughnessMap = textureRoughnessMap.replace('.png', '');
                  }
                  if (textureRoughnessMap.includes('.jpg')) {
                    textureRoughnessMap = textureRoughnessMap.replace('.jpg', '');
                  }
                }

                if (dbMaterial._Smoothness != '' && dbMaterial._Smoothness != null) {
                  roughness = 1 - dbMaterial._Smoothness;
                }

                if (dbMaterial._Opacity != '' && dbMaterial._Opacity != null) {
                  opacity = dbMaterial._Opacity;
                  if (opacity < 1) {
                    transparent = true;
                  }
                }

                if (dbMaterial._EmissionStrength != '' && dbMaterial._EmissionStrength != null) {
                  emissiveIntensity = dbMaterial._EmissionStrength;
                }

                if (dbMaterial._Metalness != '' && dbMaterial._Metalness != null) {
                  metalness = dbMaterial._Metalness;
                }

                if (type == 'Plastiques') {
                  type = 'plastic';
                }
                if (type == 'Cuirs') {
                  type = 'leather';
                }
                if (type == 'Bois') {
                  type = 'wood';
                }
              }
            });
          }

          productComponents.push({
            "color": color,
            "textureNormalMap": textureNormalMap,
            "textureDiffuseMap": textureDiffuseMap,
            "textureRoughnessMap": textureRoughnessMap,
            "normalMapStrength": normalMapStrength,
            "roughness": roughness,
            "opacity": opacity,
            "metalness": metalness,
            "emissiveIntensity": emissiveIntensity,
            "transparent": transparent,
            "type": type,
            "material": node.material,
            "geometry": node.geometry,
            "key": node.uuid
          });
        }
      });

      for (const productComponent of productComponents) {
        if (productComponent.textureNormalMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureNormalMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureNormalMap + '.png');
        }
        if (productComponent.textureDiffuseMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureDiffuseMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureDiffuseMap + '.png');
        }
        if (productComponent.textureRoughnessMap == "") {
          files.push(noTextureFile);
        }
        if (productComponent.textureRoughnessMap != "") {
          files.push('https://storage.googleapis.com/addsome-prod/textures/' + productComponent.type + '/' + productComponent.textureRoughnessMap + '.png');
        }
      }
      return [productComponents, files];
    }, [glbFile, variationIndex]);

  useEffect(() => {
    if (readyToRender) {
      const productComponentsInfo = createProductComponent(dbMaterials);
      const productComponentsFinalLocal: any[] = productComponentsInfo[0];
      let filesLocal: string[] = productComponentsInfo[1];
      setProductComponentsFinal(productComponentsFinalLocal);

      parseFiles(filesLocal, noTextureFile).then(response => {
        filesLocal = response;
        setFilesChecked(true);
        setFilesFinal(filesLocal);
      });
    }
  }, [createProductComponent, glbFile, variationIndex, readyToRender]);

  useEffect(() => {
    if (isOnView === false) {
      setReadyToRender(false);
      setFilesChecked(false);
    } else {
      setTimeout(() => {
        setReadyToRender(true);
      }, 300);
    }
  }, [isOnView]);

  useEffect(() => {
    return () => {
      Object.keys(nodes).forEach(key => {
        const node = nodes[key];
        if (node.type === 'Mesh') {
          console.log(node)
          node.geometry.dispose();
          nodes.Scene.remove(node)
          if (node.material) {
            if (Array.isArray(node.material)) {
              node.material.forEach((material) => material.dispose());
            } else {
              node.material.dispose();
            }
          }
          node.removeFromParent()
        }
      })
      useLoader.clear(TextureLoader, mediaURlDecrypted)
      useGLTF.clear(mediaURlDecrypted)
      THREE.Cache.clear()
    }
  }, []);

  return (
    <>
      {loader &&
        <div className={`${styles.loaderGLB}`}>
          <p>
            Loading...
          </p>
        </div>
      }
      {readyToRender && filesChecked && productComponentsFinal && productComponentsFinal.length > 0 && Array.isArray(productComponentsFinal) && glbFile &&
        <Suspense fallback={null}>
          {/* <button></button> */}
          <Canvas id={"idCanvas"} camera={{ fov: 70, near: 0.1, far: 1000, position: [0, 0, 3] }} shadows={true} dpr={[1, 2]}>
            {/* <Perf /> */}
            <color attach="background" args={['#E5E5E4']} />
            <ambientLight intensity={0.3} />
            {/* <spotLight intensity={0.5} angle={1} penumbra={1} position={[10, 15, 10]} 
            castShadow 
            shadow-mapSize={[512, 512]} shadow-bias={-0.0001} /> */}
            <directionalLight
              intensity={0.5}
              castShadow
              position={[10, 15, 10]}
              shadow-bias={-0.0001}
              shadow-mapSize-height={512}
              shadow-mapSize-width={512}
            />
            <Environment files={"Sky.hdr"} />
            <ObjectRender
              productComponents={productComponentsFinal}
              reference={reference}
              stopLoader={() => { setLoader(false); }}
              files={filesFinal}
            />
            <OrbitControls enableZoom={true} enablePan={false} minPolarAngle={0} maxPolarAngle={Math.PI / 2.25} maxDistance={5} minDistance={1} />
            <PerspectiveCamera makeDefault position={[0, 0, 2]} />
          </Canvas>
        </Suspense>
      }
    </>
  );
};

const parseFiles = async (files, noTextureFile) => {
  for (let i = 0; i < files.length; i++) {
    await fetch(files[i]).then((response) => {
      if (!response.ok) {
        files[i] = noTextureFile;
      }
    });
  }

  return files;
}

export default function ProductRender3d({ glbFile, objectProductVariations, variationIndex, isOnView }) {
  return (
    <div className={styles.canvasStyle} id={"canvasId"}>
      <Suspense fallback={<Loader />}>
        {glbFile && glbFile.media.url &&
          <CanvasComponent
            glbFile={glbFile}
            isOnView={isOnView}
            objectProductVariations={objectProductVariations}
            variationIndex={variationIndex}
          />
        }
      </Suspense>
    </div>
  );
}