import React, { useEffect, useState } from 'react';
import useIntersectionObserver from './hooks';

const HeroSection = ({ color, title, brandName, description, page, inlineHtml }) => {
  const [repeat, setRepeat] = useState(0);
  const ref = React.useRef();

  let isVisible;
  isVisible = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (isVisible === true && repeat < 3)
      setRepeat((prevValue) => prevValue + 1);

  }, [isVisible]);

  return (
    <>
      <div className={`heroWrapperBrand `} ref={ref}>
        {page === "brand" ?
          <img src={require("../assets/images/heroBrand.png")}
            alt="hero brand"
            className={`backgroundImageBrandAndArchitects ${isVisible && repeat < 2 && "backgroundImageBrandAndArchitectsAnimation"}`} />
          :
          <img src={require("../assets/images/heroArchitects.jpg")} alt="hero architects" className={`backgroundImageBrandAndArchitects ${isVisible && repeat < 3 && "backgroundImageBrandAndArchitectsAnimation"}`} />
        }
        <div style={{ textAlign: "left", zIndex: "5" }} className="textAreaWrapperBrandsArchitects">
          <p className={`titleStyle ${isVisible && repeat < 2 && "brandsAnimation"} "`} style={{ color: color }}>{title}</p>
          {/* <p className={`brandName ${page === "brand" && isVisible && repeat < 2 ? "descriptionHeroCommonAnimationPurple" : "descriptionHeroCommonAnimationOrange"}`}>{brandName}</p> */}
          <p className={`brandName`}>{brandName}</p>
          <h1 className={`descriptionHeroCommon ${isVisible && repeat < 2 && "brandsAnimation"} `}>{description}</h1>
          {inlineHtml}
        </div>
      </div>
    </>
  );
};

export default HeroSection;
