import React, { useState, useEffect } from 'react';
import BasicModal from '../../CommonComponents/BasicModal';
import "./style/style.modules.scss";
import {useTranslation} from "react-i18next";
import { packFeaturesList, freeTrialFeatures, monthlyPaymentFeatures } from "./utils";

const ObjectCards = () => {
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);
  const [freeTrialSubscription, setFreeTrialSubscription] = useState();
  const [unlimitedSubscription, setUnlimitedSubscription] = useState();

  useEffect(() => {
    fetch('https://api.addsome.io/architect-subscription', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
      },
    })
      .then(response => response.json())
      .then(data => {
        setFreeTrialSubscription(data.find(s => s.price === 0));
        setUnlimitedSubscription(data.find(s => s.slug === 'Unlimited'));
      })
      .catch((error) => {
        console.log("Error", error)
      });
  }, []);

  return (<>
    {openModal &&
      <BasicModal closeModal={() => { setOpenModal(false); }} />
    }
    <div className='cardsWrapper'>
      <div className="textCardStyle"
        style={{ background: `#FFFFFF` }}>
        <h1>
          {t('planCards.freeTrialSubscription.title')}
        </h1>
       
        <p style={{display: "flex", justifyContent: "center", fontSize:"14px", color:"#7E7E7E", height:"80px"}}>
          {t('planCards.freeTrialSubscription.description')}
        </p>
        <div style={{height: "50px"}}>
           <span style={{display: "flex", justifyContent: "center", color: "black", fontWeight: "500"}}>
            {t('planCards.freeTrialSubscription.downloadCredits')}
          </span>
          <div style={{display: "flex", justifyContent: "center"}}>
            <span style={{backgroundColor: "black", borderRadius: "50%", color:"white", width:"38px", height:"36px", display:"flex", alignItems:"center", justifyContent: "center"}}>
              {freeTrialSubscription && freeTrialSubscription.quota}
            </span>
          </div>
        </div>
        <div className='details'>
          <span>{t('planCards.freeTrialSubscription.title')}</span>
        </div>
        <div className="bulletPointText" style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: "2vw" }}>
          {t('planCards.freeTrialSubscription.downloadCreditsDescription')}
        </div>
        <a className="registerButton" href={`https://drive.addsome.io/register`} target="_blank" rel="noreferrer">
          <div className='buttonCards'>
            {t('planCards.getStarted')}
          </div>
        </a>
      </div>

      <div className="textCardStyle" style={{ background: `#FFFFFF` }}>
        <h1>{t('planCards.unlimitedSubscription.title')}</h1>
        <p style={{display: "flex", justifyContent: "center", fontSize:"14px", color:"#7E7E7E", height:"80px"}}>
          {t('planCards.unlimitedSubscription.description')}
        </p>
        <div style={{display: "flex", justifyContent: "center", height: "50px"}}>
          <span style={{
            backgroundColor: "black",
            color: "white",
            padding: "5px 15px",
            border: "2px solid black",
            borderRadius: "25px",
            display: "inline-block",
            fontWeight: "600",
            fontSize: "14px",
            height: "20px",
            width: "220px"
          }}>
            {t('planCards.unlimitedSubscription.downloads')}
          </span>
        </div>
        <div className='details'>
          <span> {unlimitedSubscription && unlimitedSubscription.price} € / {t('planCards.unlimitedSubscription.month')}</span>
        </div>
        <div className="bulletPointText" style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: "2vw" }}>
          {t('planCards.unlimitedSubscription.billedMonthly')}
        </div>
        <a className="registerButton" href={`https://drive.addsome.io/register`} target="_blank" rel="noreferrer">
          <div className='buttonCards'>
              {t('planCards.getStarted')}
          </div>
        </a>
      </div>
    </div>
  </>
  );
};

export default ObjectCards;
