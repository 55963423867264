import * as React from 'react';
import "./style/style.modules.scss";
import FilterButton from './FilterButton'
import useFilters from './useFilters';
import { useEffect, useState } from 'react';
import ActiveFiltersContainer from '../CommonComponents/ActiveFiltersContainer'

export default function FiltersContainer({ onFiltersChange }) {
  const {
    filterButtons,
    filters,
    closeFilter,
    objectProductsCategories,
    styleTags,
    materialTags,
    roomTags,
    colorTags,
    countries,
    brandsLexique,
    buttonIds,
    onDeleteFilters
  } = useFilters()

  useEffect(() => {
    onFiltersChange(filters, buttonIds)
  }, [filters, buttonIds.length])

  return (
    <>
      <div className="filtersContainer">
        {filterButtons.map(f =>
          <FilterButton
            key={f.id}
            icon={f.icon}
            label={f.label}
            height={f.height}
            width={f.width}
            content={f.content}
            closePopover={closeFilter}
          />
        )}
      </div>
      <ActiveFiltersContainer
        filters={filters}
        buttonIds={buttonIds}
        objectProductsCategories={objectProductsCategories}
        styleTags={styleTags}
        materialTags={materialTags}
        roomTags={roomTags}
        colorTags={colorTags}
        countries={countries}
        onResetFilter={onDeleteFilters}
        brandsLexique={brandsLexique}
      />
    </>
  );
}