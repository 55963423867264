import React, { useEffect, useState } from 'react';
import useIntersectionObserver from './hooks';
import './style/style.modules.scss';

const CardAndText = ({ imageName, title, titleColor, subtitle, description, rowReverse = false }) => {
  const brandPage = window.location.href.includes("brand");
  const ref = React.useRef();
  const [repeat, setRepeat] = useState(0);

  let isVisible;
  isVisible = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (isVisible === true && repeat < 3)
      setRepeat((prevValue) => prevValue + 1);

  }, [isVisible]);

  const getClassNames = () => {
    if (!isVisible)
      return "hideImage";
    if (!rowReverse && isVisible)
      return "imageLeft animationImageCardLeft";
    if (rowReverse && isVisible)
      return "imageRight animationImageCardRight";
  };
  const getClassNamesBrandText = () => {
    if (!brandPage && isVisible && repeat < 2)
      return "brandNameCard";
    if (brandPage && isVisible && repeat < 2)
      return "brandNameCard";
    return "brandNameCard";
  };
  return (
    <div className={`cardAndTextWrapper ${rowReverse ? 'columnReverse': ''}`} ref={ref} style={repeat < 2 ? { display: "hidden" } : {}}>
      <img
        src={require(`../assets/images/brandAndArchitectImages/${imageName}`)}
        alt="brand architect image"
        style={rowReverse ? { display: "none" } : { display: "block" }}
        className={getClassNames()} />
      <div className={`textAreaWrapper ${rowReverse ? "textLeft" : "textRight"}`}>
        <div>
          <p className={`titleStyle ${isVisible && repeat < 2 && "titleStyleCardAnimation"}`} style={{ color: titleColor }}>{title}</p>
          <p className={getClassNamesBrandText()}>{subtitle}</p>
        </div>
        <ul className={`bulletPointText ${isVisible && repeat < 2 && "bulletPointTextAnimation"}`}>
          {description.map((text, index) =>
            <li className='descriptionListCard' key={index}>{text}</li>
          )}

        </ul>

      </div>
      <img
        src={require(`../assets/images/brandAndArchitectImages/${imageName}`)}
        alt="brand architect image"
        style={rowReverse ? { display: "block" } : { display: "none" }}
        className={getClassNames()} />
    </div>
  );
};

export default CardAndText;
