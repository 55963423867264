import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import theme from '../assets';
import "./style/style.modules.scss";

const FindMoreButton = ({ text, color, page, textAlign = "center", width, contactModal, disabled }) => {
  const [isShown, setIsShown] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const buttonContent = () => {
    if (color === "purple") {
      if (contactModal) {
        if (disabled)
          return (
            <div
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              className="findMoreButtonContactModalPurpleDisabled"
              style={textAlign === "flex-start" ? { minWidth: width } : {}}>
              <p style={{ textAlign: "flex-end" }}>{isClicked ? "Sent" : text}</p>
              <img src={require("../assets/icons/arrowPurple.png")} alt="purple arrow" />
            </div>);
        return (
          <div
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className="findMoreButtonPurple"
            style={textAlign === "flex-start" ? { minWidth: width } : {}}>
            <p style={{ textAlign: textAlign }}>{isClicked ? "Sent" : text}</p>
            {isShown ?
              <img src={theme.images.longRightArrow} alt="tight arrow" />
              :
              <img src={require("../assets/icons/arrowPurple.png")} alt="purple arrow" />
            }

          </div>
        );
      }
      return (
        <div
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          className="findMoreButtonPurple findMoreButtonPurpleAnimation"
          style={textAlign === "flex-start" ? { justifyContent: textAlign, paddingLeft: "18px", minWidth: width, color: color } : {}}>
          <p style={{ textAlign: textAlign }}>{text}</p>
          {/* {isShown ?
            <img src={theme.images.longRightArrow} alt="right arrow" />
            : */}
          <img src={require("../assets/icons/arrowPurple.png")} alt="purple arrow" />
          {/* } */}

        </div>
      );
    }
    else {
      if (contactModal) {
        if (disabled)
          return (
            <div
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              className="findMoreButtonContactModalOrangeDisabled"
              style={textAlign === "flex-start" ? { minWidth: width } : {}}>
              <p style={{ textAlign: "flex-end" }}>{text}</p>
              <img src={require("../assets/icons/arrow.png")} alt="arrow" />
            </div>);
        return (
          <div
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className="findMoreButton"
            style={textAlign === "flex-start" ? { minWidth: width } : {}}>
            <p style={{ textAlign: textAlign }}>{text}</p>
            {isShown ?
              <img src={theme.images.longRightArrow} alt="right arrow" />
              :
              <img src={require("../assets/icons/arrow.png")} alt="arrow" />
            }

          </div>
        );
      }
      return (
        <div
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
          className="findMoreButton "
          style={textAlign === "flex-start" ? { justifyContent: textAlign, paddingLeft: "18px", minWidth: width, color: color } : {}}
        >
          <p>{text}</p>
          {/* {isShown ?
            <img src={theme.images.longRightArrow} alt="right arrow" />
            : */}
          <img src={require("../assets/icons/arrow.png")} alt="arrow icon" />
          {/* } */}
        </div>);
    }
  };

  return (
    <div className='findMoreButtonWrapper' style={width ? { width: width } : {}}>
      {page ?
        <Link to={page}>
          {buttonContent()}
        </Link>
        :
        <>
          {buttonContent()}
        </>
      }
    </div>
  );
};

export default FindMoreButton;