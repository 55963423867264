import React, { Component } from 'react';
import styles from './ProductViewer.module.scss';
import { WrappedComponentProps } from 'react-intl';
import ProductRender3D from './ProductRender3D.tsx';
import moment from 'moment';
import Loading from 'react-loading';

type IProps = WrappedComponentProps & {
  productId: string;
  isOnView: boolean;
  variationIndex: number;
  nrOfVariationChanges: number;
  product?: Object;
  objectProductVariations: Object[];
};

interface IMessageParameter {
  [key: string]: any;
}

const API_PLAYER_NAME = 'WebPlayerAPI';

class ProductViewer extends Component<IProps> {
  public state = {
    isLoading: true,
    glbFile: null
  };

  hasGLB = () => {

    if (this.props.product) {
      let file = this.props.product.sourceFiles.find(file => (file.extension === "glb"));
      if (file) {
        const creationDate = file.media.createDate;
        const updateDate = file.media.updateDate;
        if (moment(creationDate).year() < 2022 && moment(updateDate).year() < 2022)
          return false;
      }
      return file;
    }
    else return false;
  };
  public render() {
    return (
      <div className={styles.container}>
        {this.state.isLoading && !this.hasGLB() && (
          <div className={styles.loading}>
            <p>Loading...</p>
          </div>
        )}
        {this.hasGLB() &&
          <ProductRender3D isOnView={this.props.isOnView} glbFile={this.hasGLB()} objectProductVariations={this.props.objectProductVariations} variationIndex={this.props.variationIndex} nrOfVariationChanges={this.props.nrOfVariationChanges} />
        }

      </div>
    );
  }

  private onViewerProductSet = () => {
    this.setState({ isLoading: false });
  };
}

export default ProductViewer;
