import React from 'react';
import './style/style.modules.scss';
import HeroSection from '../../CommonComponents/HeroSection';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{'Page not found'}</title>
      </Helmet>
      <HeroSection
        color="rgba(255, 171, 71, 1)"
        brandName={"Ops!"}
        description="We can't seem to find the page you are looking for!"
        inlineHtml={<>
          <h2 className='headingStyle'>Error code: 404</h2>
          <p className='linksStyle'>Here are some helpful links instead:
          <ul>
              <li><a href='/'>Landing page</a></li>
              <li><a href='/architects'>Architects page</a></li>
              <li><a href='/'>Brands page</a></li>
            </ul>
          </p>
        </>
        }
      />
    </>
  );
};

export default NotFound;
