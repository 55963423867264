import React from 'react';
import './style/style.modules.scss';

const BlackSection = ({ number }) => {

  return (
    <div className={`blackSection${number}`}>

    </div>

  );
};

export default BlackSection;
