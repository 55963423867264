import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "./style/style.modules.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth < 800 ? "70vw" : "400px",
  bgcolor: 'background.paper',
  backgroundOpacity: "1",
  border: '2px solid #000',
  boxShadow: 54,
  p: 4,
};

export default function CustomModal({title, subtitle, okButton, cancelButton, closeModal }) {

  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon style={{ position: "absolute", right: "15px", top: "15px", cursor: "pointer" }} onClick={() => { closeModal(); }} />
          <div className='modalWrapper modalWrapperBasic'>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} component="h1">
              {subtitle}
            </Typography>
          </div>
          <div className="flex" style={{justifyContent:"center", alignItems:"center", display:"flex"}}>
            {okButton}
            {cancelButton}
          </div>
        </Box>
      </Modal>
    </div>
  );
}