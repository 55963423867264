import React from "react";
import "./style/style.modules.scss";
import Button from '../CommonComponents/Button';
import {useTranslation} from "react-i18next";

const ContactUs = ({ buttonColor = "#151515", openModal }) => {
  const { t } = useTranslation('common');
  return (
    <div className='contactUs'>
      <div className="contactUsWrapper">
        <img src={require("../assets/images/contactUsSection.png")} className="contactUsBackgroundImd" alt="contact background" />
        <img src={require("../assets/images/ADDSOME.png")} className="addsomeImage" alt="addsome image" />

        <div className="textAndButtonArea">
          <p>{t('footer.questions')}</p>
          <Button
            text={t('footer.contact')}
            color="white"
            backgroundColor={buttonColor}
            fontFamily="Inter"
            openModal={() => { openModal(); }} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;