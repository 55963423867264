import React from "react";

const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useLayoutEffect(() => {
    if (ref)
    {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options);

      if (ref.current)
      {
        observer.observe(ref.current);
      }

      return () => {
        observer.unobserve(ref.current);
      };
    }
  }, []);

  return isIntersecting;
};

export default useIntersectionObserver;