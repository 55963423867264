import React, { useState } from 'react';
import "./style/style.modules.scss";
import Button from "./Button";
import { Link } from 'react-router-dom';
import theme from '../assets';
import Icon from '@mui/material/Icon';
import FindMoreButton from '../CommonComponents/FindMoreButton';
import {useTranslation} from "react-i18next";

const Header = ({ text, color, openModal }) => {
  const [changeSvg, setChangeSvg] = useState(false);
  const { t } = useTranslation('common');

  // setTimeout(() => {
  //   setChangeSvg(true);
  // }, 800);
  const brandPage = window.location.href.includes("brand");
  return (
    <div className='headerWrapper'>
      <div className='firstPart'>
        <Link to={"/"}>
        {/* {changeSvg ?
            <img src={theme.images.addsomeLogo} alt="addsome logo"/>
            :
            <img src={brandPage ? theme.images.addsomeLogoPurple : theme.images.addsomeLogoOrange} alt="addsome coloured logo"/>
          } */}
          <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8498 39.2275V51H13.9249C6.23422 51 0 44.7658 0 37.0751C0 30.1853 5.00343 24.4644 11.5751 23.3476V11.5751H23.1502V0H37.0751C44.7658 0 51 6.23422 51 13.9249C51 20.8739 45.9103 26.6338 39.2552 27.6802C38.3295 33.5682 33.714 38.2309 27.8498 39.2275ZM27.8498 34.4233C31.8067 33.3842 34.7253 29.7831 34.7253 25.5C34.7253 20.4049 30.5951 16.2747 25.5 16.2747H16.2747V23.1502H27.8498V34.4233ZM27.8498 11.7725C33.5766 12.7457 38.1125 17.2153 39.1841 22.908C43.2629 21.9542 46.3003 18.2944 46.3003 13.9249C46.3003 8.82977 42.1702 4.69966 37.0751 4.69966H27.8498V11.7725ZM23.1502 46.3003V27.8498H13.9249C8.82977 27.8498 4.69966 31.9799 4.69966 37.0751C4.69966 42.1702 8.82977 46.3003 13.9249 46.3003H23.1502Z" fill="white" />
          </svg>
        </Link>
        <Link to={"/"}>
          <div className="headerTitle">ADDSOME</div>
        </Link>
      </div>
      <div className='secondPart'>
        <Button text={text} color={color} variant="outlined" openModal={() => { openModal(); }} />
        <a href={`https://drive.addsome.io/register?subscription=Standard10`}>
          <FindMoreButton text={`${t('planCards.getStarted')} - ${t('planCards.isFree')}`}></FindMoreButton>
        </a>

        <Icon onClick={() => {window.location.href = 'https://drive.addsome.io/register'}} className='loginIcon'>person_add_alt_icon</Icon>
        <Icon onClick={() => { openModal();}} className='loginIcon'>login</Icon>
      </div>
    </div>
  );
};

export default Header;