import React, { useState, useEffect } from 'react';
import CardAndText from '../../CommonComponents/CardAndText';
import Footer from '../../CommonComponents/Footer';
import Header from '../../CommonComponents/Header';
import HeroSection from '../../CommonComponents/HeroSection';
import ShortDescriptionSection from './ShortDescriptionSection';
import BrandsSection from '../LandingPage/BrandsSection';
import "./style/style.modules.scss";
import FindMoreButton from '../../CommonComponents/FindMoreButton';
import ContactUs from '../../CommonComponents/ContactUs';
import { Helmet } from 'react-helmet';
import BasicModal from '../../CommonComponents/BasicModal';
import ContactModal from '../../CommonComponents/ContactModal';
import BrandSection from './BrandsSection';
import mixpanel from 'mixpanel-browser';
import {useTranslation} from "react-i18next";

const BrandPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    mixpanel.init('1649b8db3bc1a1cf454dc144a3959e5a', { debug: true });
    mixpanel.track('Page viewed: Brands page', {});

    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      {openModal &&
        <BasicModal closeModal={() => { setOpenModal(false); }} />
      }
      {openContactModal &&
        <ContactModal closeModal={() => { setOpenContactModal(false); }} />
      }
      <Helmet>
        <title>{'Addsome for brands'}</title>
      </Helmet>
      <div className='brandWrapper'>
        <Header color="#FFFFFF" text={t('global.login')} openModal={() => window.location.href = 'https://drive.addsome.io/login'} />
        <div className='brandsTitle'>{t('global.brands')}</div>
        <HeroSection
          page="brand"
          color="#C3C3C3"
          title="ADDSOME"
          brandName={t('brandsPage.giveABoost')}
          description={t('brandsPage.imagePlatform')}
        />
        {/* <ShortDescriptionSection /> */}
        <div className='cardWrapper'>
          <CardAndText imageName="brand1.png"
            title={t('brandsPage.getClear').toUpperCase()}
            titleColor="#C3C3C3"
            subtitle={t('brandsPage.boostVisibility')}
            description={[t('brandsPage.getProductsInFront'),
            t('brandsPage.helpDesigners'),
            t('brandsPage.connect')]}
          />
          <CardAndText imageName="brand2.png"
            title={t('brandsPage.weMake').toUpperCase()}
            titleColor="#C3C3C3"
            subtitle={t('brandsPage.youMake')}
            description={[t('brandsPage.weTurnProducts'),
              t('brandsPage.bePart'),
              t('brandsPage.powerfulFilters')]}
            rowReverse
          />
          <CardAndText imageName="brand3.png"
            title={t('brandsPage.monitor').toUpperCase()}
            titleColor="#C3C3C3"
            subtitle={t('brandsPage.productPerformance')}
            description={[t('brandsPage.realTimeStatistics'),
              t('brandsPage.useAddsome'),
              t('brandsPage.seeBrandsPromoting')]}
          />
        </div>
        <BrandSection />
      </div>
      {/* <BrandsSection withText={false} withButton={false} background="black" /> */}
      {/* <div className='arrangeButton'> */}
      {/* <FindMoreButton text="Discover all Brands" color="purple" /> */}
      {/* </div> */}
      <ContactUs openModal={() => setOpenContactModal(true)} />
      <Footer setOpenContactModal={setOpenContactModal} />

    </>
  );
};

export default BrandPage;
