import * as React from 'react';
import "./style/style.modules.scss";
import FilterButton from './FilterButton'
import { useEffect, useState } from 'react';
import { Chip } from '@mui/material'

export default function ActiveFiltersContainer({
  filters,
  onResetFilter,
  objectProductsCategories,
  styleTags,
  materialTags,
  roomTags,
  colorTags,
  countries,
  brandsLexique,
  buttonIds
}) {
  const [filtersChip, setFiltersChip] = useState([])
  const [newFilters, setNewFilters] = useState([])

  useEffect(() => {
    setNewFilters(filters)
  }, [filters, Object.keys(filters).length, Object.values(filters).flat().length])

  const findCategoryName = (categoryId) => {
    let name = ''
    objectProductsCategories.forEach(op => {
      const category = op.childs.find(c => c.id === categoryId)
      if (category) {
        name = category.name
      }
    })
    return name;
  }
  const findBrandName = (brandId) => {
    let name = ''
    Object.values(brandsLexique).forEach(bl => {
      const brand = bl.find(b => b.id === brandId)
      if (brand) {
        name = brand.name
      }
    })
    return name;
  }
  const findStyleTagName = (styleId) => {
    const style = styleTags.find(s => s.id === styleId)
    if (style) {
      return style.name
    }
  }
  const findMaterialTagName = (materialId) => {
    const material = materialTags.find(m => m.id === materialId)
    if (material) {
      return material.name
    }
  }
  const findRoomTagName = (roomId) => {
    const room = roomTags.find(r => r.id === roomId)
    if (room) {
      return room.name
    }
  }
  const findColorTagName = (colorId) => {
    const color = colorTags.find(c => c.id === colorId)
    if (color) {
      return color.name
    }
  }
  const findCountryName = (countryCode) => {
    const country = Object.keys(countries).find(key => countries[key] === countryCode)
    if (country) {
      return country
    }
  }

  useEffect(() => {
    let chips = []
    Object.keys(newFilters).forEach(key => {
      switch (key) {
        case 'haveObjectProductModelBundle':
          chips.push(filters[key] ? { text: 'With 3D', keys: [key] } : { text: 'Without 3D', keys: [key] })
          break;
        case 'priceMin':
          chips.push({ text: `${filters[key]}-${filters['priceMax']}€`, keys: [key, 'priceMax'] })
          break;
        case 'objectProductCategoryIds':
          filters['objectProductCategoryIds'].forEach(opc => {
            const name = findCategoryName(opc)
            chips.push({ text: name, keys: [key], value: opc })
          })
          break;
        case 'brandIds':
          filters['brandIds'].forEach(b => {
            const name = findBrandName(b)
            chips.push({ text: name, keys: [key], value: b })
          })
          break;
        case 'styleTagIds':
          filters['styleTagIds'].forEach(s => {
            const name = findStyleTagName(s)
            chips.push({ text: name, keys: [key], value: s })
          })
          break;
        case 'materialTagIds':
          filters['materialTagIds'].forEach(m => {
            const name = findMaterialTagName(m)
            chips.push({ text: name, keys: [key], value: m })
          })
          break;
        case 'roomTagIds':
          filters['roomTagIds'].forEach(r => {
            const name = findRoomTagName(r)
            chips.push({ text: name, keys: [key], value: r })
          })
          break;
        case 'colorTagIds':
          filters['colorTagIds'].forEach(c => {
            const name = findColorTagName(c)
            chips.push({ text: name, keys: [key], value: c })
          })
          break;
        case 'madeIn':
          filters['madeIn'].forEach(c => {
            const name = findCountryName(c)
            chips.push({ text: name, keys: [key], value: c })
          })
          break;
        case 'widthMin':
          chips.push({
            text: `Width: ${filters['widthMin']}-${filters['widthMax']}cm | Height: ${filters['heightMin']}-${filters['heightMax']}cm | Depth: ${filters['depthMin']}-${filters['depthMax']}cm`,
            keys: ['widthMin', 'widthMax', 'heightMin', 'heightMax', 'depthMin', 'depthMax']
          })
      }
    })
    setFiltersChip(chips)
  }, [newFilters, buttonIds.length])

  return (
    <div className="activeFiltersContainer">
      {filtersChip.length > 0 &&
        filtersChip.map((c, idx) => <Chip key={idx} label={c.text} variant="outlined" onDelete={() => onResetFilter(c)} />)
      }
    </div>
  );
}