import { useEffect, useState } from 'react'

const VideoSection = () => {
  const videoWrap = document.getElementById('vidContainer')
  const handleNavigation = () => {
    const videoWrap = document.getElementById('vidContainer')
    if (window.scrollY > videoWrap.offsetHeight) {
      document.getElementById('addsome_video').play()
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

  }, [handleNavigation])

  return (
    <div className="videoSectionContainer" id="vidContainer">
      <div className="videoSection">
        <video id="addsome_video" poster={require('../../assets/images/videoThumbnail.jpg')} className="video-js vjs-default-skin" controls width="auto" height="auto" preload="auto" controls muted loop>
          <source type="video/mp4" src={require('../../assets/video/Video_Preview.mp4')} />
        </video>
      </div>
    </div>
  )

}

export default VideoSection