import React, { useEffect, useState } from 'react';
import FindMoreButton from '../../CommonComponents/FindMoreButton';
import './style/style.modules.scss';
import theme from "../../assets";
import useIntersectionObserver from '../../CommonComponents/hooks';
import {useTranslation} from "react-i18next";

const HeroSection = ({ withText = true, withButton = true, background = "rgba(27, 27, 27, 0.3)" }) => {

  const [changeSvg, setChangeSvg] = useState(false);
  const [repeat, setRepeat] = useState(0);
  // setTimeout(() => {
  //   setChangeSvg(true);
  // }, 800);
  const ref = React.useRef();
  const { t } = useTranslation('common');

  let isVisible;
  isVisible = useIntersectionObserver(ref, { threshold: 0.5 });

  useEffect(() => {
    if (isVisible === true && repeat < 3)
      setRepeat((prevValue) => prevValue + 1);

  }, [isVisible]);


  return (
    <div className="brandsDescriptionWrapper" ref={ref}>
      <div className="backgroundRepeat"></div>
      {/* <img
        src={require("../../assets/images/seeMoreDetailsBackrgound.jpg")}
        alt="more details background"
        className={`backgroundBrandSectionLanding ${isVisible && repeat < 2 && "backgroundBrandSectionLandingAnimation"} ${!withText && "smallerHeightLogoSection"}`} /> */}
      <div className='textAreaBrands'>
        {/* {withText &&
          <>
            <span className={`titleAndIcon `}>
              <img src={theme.images.addsomeLogo} alt='addsome logo' />

              <p className={`titleText colorBrands ${isVisible && repeat < 2 && "titleTextAnimation"}`}> Addsome for brands</p>
            </span>
            <p className={`descriptionBrand ${isVisible && repeat < 2 && "descriptionBrandAnimation"} `}>
              Get your products in front of a lot more customers
            </p>
            <ul className={`bulletPointText ${isVisible && repeat < 2 && "bulletPointTextAnimation"} `}>
              <li>Addsome puts 3D files of your design objects in the hands of its growing base of 3000+ interior designers.</li>
              <li>Promote top products.</li>
              <li>Learn which items get the most views.</li>
              <li>Boost your brand visibility across the globe.</li>
            </ul>
          </>
        } */}

        <div className='logoSection'>
          <div className='logoRow'>
            <img src={require("../../assets/logos/logoSection/logo1.png")} alt="logo 1" />
            <img src={require('../../assets/logos/logoSection/logo2.png')} style={{ height: '3.5vh' }} alt="logo 2" />
            <img src={require('../../assets/logos/logoSection/logo3.png')} alt="logo 3" />
            <img src={require('../../assets/logos/logoSection/logo4.png')} alt="logo 4" />
            <img src={require('../../assets/logos/logoSection/logo5.png')} style={{ height: '3.5vh' }} alt="logo 5" />
            <img src={require('../../assets/logos/logoSection/logo6.png')} alt="logo 6" />
          </div>
          <div className={'logoRow'}>
            <img src={require('../../assets/logos/logoSection/logo7.png')} alt="logo 7" />
            <img src={require('../../assets/logos/logoSection/logo8.png')} style={{ height: '3.5vh' }} alt="logo 8" />
            <img src={require('../../assets/logos/logoSection/logo9.png')} style={{ height: '4.5vh' }} alt="logo 9" />
            <img src={require('../../assets/logos/logoSection/logo10.png')} style={{ height: '3.5vh' }} alt="logo 10" />
            <img src={require('../../assets/logos/logoSection/logo11.png')} alt="logo 11" />
          </div>
          <div className='logoRow'>
            <img src={require('../../assets/logos/logoSection/logo12.png')} style={{ height: '3vh' }} alt="logo 12" />
            <img src={require('../../assets/logos/logoSection/logo13.png')} alt="logo 13" />
            <img src={require('../../assets/logos/logoSection/logo14.png')} style={{ height: '4.5vh' }} alt="logo 14" />
            <img src={require('../../assets/logos/logoSection/logo15.png')} style={{ height: '4vh' }} alt="logo 15" />
            <img src={require('../../assets/logos/logoSection/logo16.png')} style={{ height: '4.5vh' }} alt="logo 16" />
            <img src={require('../../assets/logos/logoSection/logo17.png')} style={{ height: '4vh' }} alt="logo 17" />
          </div>
        </div>
        <div className='textArea'>
          <div className='designersTitle'>{t('global.brands')}</div>
          <div className="flexArea">
            <div className='designersDescription'>
              <h1 className='description' id="titleId">
                {t('landingPage.getYourProducts')}
              </h1>
              <ul className={`bulletPointText ${isVisible && repeat < 2 && "bulletPointTextAnimation"}`}>
                <li>{t('landingPage.addsome')}</li>
                <li>{t('landingPage.promote')}</li>
                <li>{t('landingPage.learn')}</li>
                <li>{t('landingPage.boost')}.</li>
              </ul>
            </div>
            <img src={require("../../assets/images/logoLandingPage.png")} alt="Logo Addsome"></img>
          </div>
          <div className='buttonSection'>
            <FindMoreButton text={t('global.findOutMore')} color="purple" page={"/brands"} />
          </div>
        </div>
        {/* {withButton &&
        <div className='findOutMoreBrands'>
          <div className='buttonSectionBrands'>
            <FindMoreButton text="Find out more" color="purple" page={"/brands"} />
          </div>
        </div>
      } */}
      </div>
    </div>
  );
};

export default HeroSection;
