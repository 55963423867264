import * as React from 'react';
import { useState, useEffect } from 'react'
import "./style/style.modules.scss";
import { Button, Popover, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import {useTranslation} from "react-i18next";

export default function FilterButton({ icon, label, content, height, width, closePopover }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation('common');
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (closePopover) {
      handleClose()
    }
  }, [closePopover])
  return (
    <div>
      <span variant="contained" className="filterButton" onClick={handleClick}>
        <Icon>{icon}</Icon>
        <div>{t(`filters.${label.toLowerCase()}`)}</div>
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: `${width}`, height: height },
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </div>
  );
}