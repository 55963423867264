import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "./style/style.modules.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth < 800 ? "79vw" : "500px",
  bgcolor: 'background.paper',
  backgroundOpacity: "1",
  border: '2px solid #000',
  boxShadow: 54,
  p: 4,
};

export default function BasicModal({ icon, title, subtitle, description, closeModal }) {
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon style={{ position: "absolute", right: "15px", top: "15px", cursor: "pointer" }} onClick={() => { closeModal(); }} />
          <div className='modalWrapper modalWrapperBasic'>
            <img src={require("../assets/icons/addsomeIconBlack.png")} alt="addsome black icon" />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              We are working to provide you with the best experience
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} component="h1">
              We’ll be back very soon
            </Typography>
            <p>and you will find the largest catalog of 3D furniture products</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}