import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import "./style/style.modules.scss";
import { TextField, ThemeProvider, useFormControl } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import FindMoreButton from './FindMoreButton';
import {useTranslation} from "react-i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: window.innerWidth < 800 ? "79vw" : "1100px",
  bgcolor: 'background.paper',
  backgroundOpacity: "1",
  boxShadow: 54,
  p: 4,

  '.inputBackgorund input:-webkit-autofill': {
    boxShadow: "0 0 0px 1000px #ffffff inset",
    '-webkit-text-fill-color': "#000000"
  },

  'input': {
    width: "13.69vmax",
    // backgroundColor: "#151515",
    height: "14px",
    borderRadius: "12px",
    color: '#000000',
    '&::placeholder': {
      color: '#000000',
      opacity: "1",
      textOverflow: 'ellipsis !important',
    }

  },
  'textarea': {
    width: "13.69vmax",
    // backgroundColor: "#151515",
    height: "14px",
    borderRadius: "12px",
    color: '#000000',
    '&::placeholder': {
      color: '#000000',
      opacity: "1",
      textOverflow: 'ellipsis !important',
    }

  }
};

const theme = createTheme({
  palette: {
    secondary: {
      light: '#FFFFFF80',
      main: '#FFFFFF80',
      dark: '#FFFFFF80',
      contrastText: '#FFFFFF80',
    },
  },

});
export default function BasicModal({ closeModal }) {
  const [errorName, setErrorName] = React.useState(null);
  const [errorLastName, setErrorLastName] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [disableButton, setDisableButton] = React.useState(false);
  const { t } = useTranslation('common');
  const [buttonMessage, setButtonMessage] = React.useState(t('contact.sendMessage'));


  const clickSendButton = () => {
    if (errorName === false && errorLastName === false && errorEmail === false && errorMessage === false) {
      setButtonMessage("Sent");
      setDisableButton(true);
    }
  };

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backgroundColor: " #1b1b1b!important" }}
        >
          <Box sx={style} className='entireModalWrapper'>
            <CloseIcon style={{ position: "absolute", right: "15px", top: "15px", cursor: "pointer", color: "black" }} onClick={() => { closeModal(); }} />
            <div className='entireModalWrapper entireModalWrapper'>
              <div className='leftSideModal'>
                <img src={require("../assets/images/contactUsSection.png")} className="contactUsBackgroundImd" alt="contact background" />
                <img src={require("../assets/images/ADDSOME.png")} className="addsomeImage" alt="addsome image" />
              </div>
              <div className='modalWrapper'>
                <form
                  sx={{ width: '25ch' }}
                  action="https://formsubmit.co/hello-inbox@addsome.io"
                  onSubmit={clickSendButton}
                  method="POST">
                  <div className='formStyle'>
                    <div className='subcategoryOfInputs'>
                      <div className='inputField'>
                        <TextField
                          placeholder={t('contact.firstName')}
                          error={errorName}
                          name="FirstName"
                          required
                          color="secondary"
                          className='inputBackgorund'
                          InputLabelProps={{
                            classes: {
                              root: { color: "black" }
                            }
                          }}
                          style={{
                            width: "13.69vmax", backgroundColor: "#ffffff", height: "49px",
                            borderRadius: "12px", color: "#000000",
                          }}
                          onChange={(e) => {
                            if (e.target.value === "")
                              setErrorName(true);
                            else {
                              setErrorName(false);
                            }
                          }} />
                      </div>
                      <div className='inputField'>
                        <TextField
                          placeholder={t('contact.lastName')}
                          error={errorLastName}
                          name="Last Name"
                          required
                          color="secondary"
                          className='inputBackgorund'
                          style={{
                            width: "13.69vmax", backgroundColor: "#ffffff", height: "49px",
                            borderRadius: "12px",
                            'input': {
                              '&::placeholder': {
                                color: 'blue'
                              }
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value === "")
                              setErrorLastName(true);
                            else {
                              setErrorLastName(false);
                            }
                          }} />
                      </div>
                    </div>
                    <div className='subcategoryOfInputs'>
                      <div className='inputField'>
                        <TextField
                          placeholder={t('contact.company')}
                          name="Company Name"
                          className='inputBackgorund'
                          color="secondary"
                          style={{
                            width: "13.69vmax", backgroundColor: "#ffffff", height: "49px",
                            borderRadius: "12px"
                          }}
                        />


                      </div>
                      <div className='inputField'>
                        <TextField
                          error={errorEmail}
                          placeholder={t('contact.email')}
                          name="Email"
                          required
                          className='inputBackgorund'
                          color="secondary"
                          InputLabelProps={{
                            classes: {
                              root: { color: "white" }
                            }
                          }}
                          style={{
                            width: "13.69vmax", backgroundColor: "#ffffff", height: "49px",
                            borderRadius: "12px"
                          }}
                          onChange={(e) => {
                            if (e.target.value === "")
                              setErrorEmail(true);
                            else {
                              setErrorEmail(false);
                            }
                          }}
                        />
                      </div>

                    </div>
                    <div className='subcategoryOfInputs'>
                      <div className='inputField'>
                        <TextField
                          placeholder={t('contact.message')}
                          error={errorMessage}
                          multiline
                          rows={5}
                          required
                          className='inputBackgorund'
                          color="secondary"
                          name="Message"
                          style={{ width: "28.20vmax", backgroundColor: "#ffffff", borderRadius: "12px" }}
                          onChange={(e) => {
                            if (e.target.value === "")
                              setErrorMessage(true);
                            else {
                              setErrorMessage(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="_next" value={window.location.href} />
                  <input type="hidden" name="_captcha" value="false" />
                  <button
                    type="submit"
                    disabled={disableButton}
                  >
                    <FindMoreButton
                      contactModal
                      disabled={disableButton}
                      text={buttonMessage}
                      color={window.location.href.includes("architects") ? "orange" : "purple"}
                      page={null}
                      textAlign="flex-start"
                    // width={window.innerWidth > 1000 && "28.2vw"}
                    />
                  </button>
                </form>
              </div>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
    </div>
  );
}