import React, { useEffect, useState } from 'react';
import './style/style.modules.scss';
import { TextField, Stack, Pagination, Button } from '@mui/material';
import ProductViewer from '../ProductViewer/ProductViewer.tsx'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import logo3D from "../../assets/logos/3DView.svg"
import Loading from "react-loading";
import FiltersContainer from '../../CommonComponents/FiltersContainer'
import ActiveFiltersContainer from '../../CommonComponents/ActiveFiltersContainer'
import ReactLoading from 'react-loading';
import {useTranslation} from "react-i18next";
import CustomModal from "../../CommonComponents/CustomModal";

const ProductsSection = () => {
  const [page, setPage] = useState(1)
  const [productPerPage, setProductPerPage] = useState(6)
  const [products, setProducts] = useState([])
  const [productViewId, setProductViewId] = useState(0)
  const [cardsPage, setCardsPage] = useState(products.filter((c, idx) => idx >= productPerPage * (page - 1) && idx < productPerPage * page))
  const [product, setProduct] = useState({})
  const [dimensions, setDimensions] = useState([])
  const [variations, setVariations] = useState([])
  const [variation, setVariation] = useState(null)
  const [variationIndex, setVariationIndex] = useState(0)
  const [count, setCount] = useState(Math.ceil(products.length / productPerPage))
  const [previewUrl, setPreviewUrl] = useState('');
  const [productsLoading, setProductsLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [querySearch, setQuerySearch] = useState('');
  const [buttonIds, setButtonIds] = useState([])
  const { t } = useTranslation('common');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setProductsLoading(true)
    fetch('https://api.addsome.io/products?' + new URLSearchParams({
      skip: 0,
      take: 24,
      order: JSON.stringify({ promoted: "DESC", createdAt: "DESC" }),
      ...filters,
      query: querySearch,
    }), {
      method: 'GET',
      headers: {
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
        'Accept': 'application/json, text/plain, */*'
      },
    })
      .then(response => response.json())
      .then(data => {
        setProducts(data.data)
        setProductsLoading(false)
      })
      .catch((error) => {
        console.log("Error", error)
        setProductsLoading(false)
      });
  }, [filters, querySearch, buttonIds.length, buttonIds])

  useEffect(() => {
    setCardsPage(products.filter((c, idx) => idx >= productPerPage * (page - 1) && idx < productPerPage * page))
    setCount(Math.ceil(products.length / productPerPage))
  }, [products])

  const showProduct = (id) => {
    setProductLoading(true)

    fetch(`https://api.addsome.io/products/${id}`, 
    {
      method: 'GET',
      headers: {
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
        'Accept': 'application/json, text/plain, */*'
      },
    })
      .then(response => response.json())
      .then(data => {
        setProduct(data)
        document.getElementById("productViewer").scrollIntoView();
        getVariations(data.objectProduct.id, data.id)
        getDimensions(data.objectProduct.id)
        if (!data.sourceFiles.length) {
          setPreviewUrl(data.pictures[0].media.url)
        }
      })
      .catch((error) => {
        console.log("Error", error)
      });
  }

  const getVariations = (objectProductId, productId) => {
    fetch(`https://api.addsome.io/object-products/${objectProductId}/variations`, 
    {
      method: 'GET',
      headers: {
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
        'Accept': 'application/json, text/plain, */*'
      },
    })
    .then(response => response.json())
    .then(data => {
      setVariations(data)
      if (data.length > 0) {
        setVariation(data[0])
        setVariationIndex(0)
      }
      setProductViewId(productId)
    })
    .catch((error) => {
      console.log("Variations error", error)
      setProductLoading(false)
    })
  };

  const getDimensions = (objectProductId) => {
    fetch(`https://api.addsome.io/object-product-dimensions/${objectProductId}`, 
    {
      method: 'GET',
      headers: {
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
        'Accept': 'application/json, text/plain, */*'
      },
    })
    .then(response => response.json())
    .then(data => {
      setDimensions(data)
    })
  };

  const handleChange = (pageNumber) => {
    if (pageNumber !== 5) {
      setPage(pageNumber)
      setCardsPage(products.filter((c, idx) => idx >= productPerPage * (pageNumber - 1) && idx < productPerPage * pageNumber))
    } else {
      window.location.href = 'https://drive.addsome.io/catalog'
    }
  }

  const handleChangeVariation = (variation) => {
  }
  const setPictureView = (picture) => {
    setPreviewUrl(picture.media.url)
  }
  const onFiltersChange = (newFilters, buttonIds) => {
    setFilters(newFilters)
    setButtonIds(buttonIds)
  }
  const updateQuery = (e) => {
    setQuerySearch(e.target.value)
    setPage(1)
  }

  const onReset = () => {
    setProductViewId(0); 
    setPreviewUrl(''); 
    setVariation(null); 
    setVariationIndex(0); 
    setDimensions([]); 
    setVariations([]);
  }

  useEffect(()=> {
    setProductLoading(false)
  }, [productViewId])

  return (
    <div className='wrapperProductsSection'>
      <div className='productsSection' id='productViewer'>
        {productViewId === 0 ?
          <>
            <TextField
              placeholder={t('filters.search')}
              name="productSearch"
              fullWidth
              size="small"
              className='searchField'
              onBlur={updateQuery}
              onKeyDown={(e) => e.key == 'Enter' && updateQuery(e)}
            />
            <FiltersContainer onFiltersChange={onFiltersChange} />
            {productsLoading || productLoading ?
              <div className='centerLoader loaderContainer'>
                {productsLoading ? <div className="textLoading">{t('filters.pleaseWait')}</div> 
                  : <div></div>
                }
                <ReactLoading type='spin' color='#FFFFFF' style={{ height: '70px', width: '70px' }}></ReactLoading>
              </div> :
              <>
                <div className='productsCardWrapper'>
                  {cardsPage.length > 0 && cardsPage.map((c) =>
                    <div className="productCard" key={c.id} onClick={() => showProduct(c.id)}>
                      {c.thumbnail && <img src={c.thumbnail.media.url} alt="addsome icon" className='productThumbnail' />}
                      <h3>{c.name}</h3>
                      <h5>{c.brand.name}</h5>
                    </div>
                  )}
                  {cardsPage.length === 0 &&
                    <div className="noProductMatch">{t('filters.sorry')}</div>
                  }
                </div>
                <Stack spacing={2}>
                  <Pagination count={count + 1} shape="rounded" page={page} onChange={(e, pageNumber) => handleChange(pageNumber)} />
                </Stack>
              </>
            }
          </> :
          <>
              <div className="backArrow">
                  <img src={require("../../assets/icons/backArrow.png")} width="20" onClick={() => onReset()}></img>
                </div>
                <div className='productViewContainer'>
                  <div className="productViewWrapper">
                    {previewUrl && variations.length ?
                      <img className="productPicture" src={previewUrl} /> :
                      <ProductViewer className="productView" isOnView={true} productId={product.id} variationIndex={variationIndex} nrOfVariationChanges={0} product={product} objectProductVariations={variations} />
                    }
                    <div className="gallery">
                      {product.sourceFiles.length > 0 && <img src={logo3D} width="90" height="90" onClick={() => setPreviewUrl('')} className={previewUrl ? '' : 'borderHighlight'} style={{ objectFit: 'none' }} />}
                      {product.pictures.map(p =>
                        <img src={p.media.url} width="90" height="90" onClick={() => setPictureView(p)} className={previewUrl == p.media.url ? 'borderHighlight' : ''} />
                      )}
                    </div>
                  </div>
                  <div className="productInformation">
                    <div className="brandInfos">
                      {product.brand.logo &&
                        <img src={product.brand.logo.url} width="120" />
                      }
                      <div>
                        <h2>{product.name}</h2>
                        <h3>{product.brand.name}</h3>
                        {product.brand.madeIn &&
                          <span>{`${t('global.madeIn')} ${product.brand.madeIn}`}</span>
                        }
                      </div>
                    </div>
                    {variation &&
                      <div className="variations flex">
                        <h4>{t('global.variations')}</h4>
                        <span>
                          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">{t('global.variation')}</InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={variation.id}
                              label={t('global.variation')}
                              onChange={handleChangeVariation}
                            >
                              {variations.map((v, idx) =>
                                <MenuItem key={v.id} value={v.id} onClick={() => { setVariation(v); setVariationIndex(idx) }}>{v.name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </span>
                      </div>
                    }
                    {dimensions.length > 0 &&
                      <div className="dimensions flex">
                        <h4>{t('global.dimensions')}</h4>
                        <span>
                          {dimensions.map(d =>
                            <div className="dimensionsContainer">
                              {d.width > 0 && <div>{t('global.width')}: {d.width / 10} cm</div>}
                              {d.height > 0 && <div>{t('global.height')}: {d.height / 10} cm</div>}
                              {d.depth > 0 && <div>{t('global.depth')}: {d.depth / 10} cm</div>}
                              {d.diameter > 0 && <div>{t('global.diameter')}: {d.diameter / 10} cm</div>}
                              {d.weight > 0 && <div>{t('global.weight')}: {d.weight / 100} kg</div>}
                            </div>
                          )}
                        </span>
                      </div>
                    }
                    {product.collection &&
                      <div className="collection flex">
                        <h4>{t('landingPage.collection')}</h4>
                        <span>{product.collection}</span>
                      </div>
                    }
                    {product.designers.length > 0 &&
                      <div className="designer flex">
                        <h4>{t('landingPage.designedBy')}</h4>
                        <span>
                          {product.designers.map(d => <div>{d.name}</div>)}
                        </span>
                      </div>
                    }
                    {product.description &&
                      <div className="productDescription flex">
                        <h4>{t('landingPage.description')}</h4>
                        <span>{product.description}</span>
                      </div>
                    }
                      <div className="access3D flex">
                        <Button className="flex access3D-button" style={{width: "100%"}} onClick={() => setOpenModal(true)}>
                          <span>{t('landingPage.access3D')}</span>
                        </Button>
                      </div>
                  </div>
                  {openModal &&
                    <CustomModal
                      closeModal={() => { setOpenModal(false); }}
                      title={t('landingPage.modalTitle')}
                      subtitle={""}
                      okButton={
                        <Button className="flex access3D-button" style={{marginRight:10}} onClick={() => window.location.href = 'https://drive.addsome.io/login'}>
                          <span>{t('landingPage.login')}</span>
                        </Button>
                      }
                      cancelButton={
                        <Button className="flex access3D-button" onClick={() => window.location.href = 'https://drive.addsome.io/register'}>
                          <span>{t('landingPage.register')}</span>
                        </Button>
                      }
                    />
                  }
                </div>
                </>
              }
      </div>
    </div >

  );
};

export default ProductsSection;
