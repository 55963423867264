import React from 'react';
import "./style/style.modules.scss";

const Button = ({ text, color, fontFamily, withArrow = false, openModal, backgroundColor = "linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%)" }) => {

  return (
    // <a href="https://drive.addsome.io/login" target="_blank" rel="noreferrer">
    <div style={{ color: color, backgroundColor: backgroundColor, cursor: "pointer", fontFamily: fontFamily, border: `1px solid ${color}` }} className="button" onClick={() => openModal()}>

      {text}
      {withArrow &&
        <img src={require("../assets/icons/blackRightArrow.png")} color="black" alt="right arrow" />

      }
    </div>
    // </a>

  );
};

export default Button;