import * as React from 'react';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider'
import { Button, ButtonGroup } from '@mui/material';
import Flag from 'react-world-flags';
import {useTranslation} from "react-i18next";

export default function useFilters() {
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(10000)
  const [minPriceSelected, setMinPriceSelected] = useState(0)
  const [maxPriceSelected, setMaxPriceSelected] = useState(10000)
  const [minHeightSelected, setMinHeightSelected] = useState(0)
  const [maxHeightSelected, setMaxHeightSelected] = useState(500)
  const [minWidthSelected, setMinWidthSelected] = useState(0)
  const [maxWidthSelected, setMaxWidthSelected] = useState(500)
  const [minDepthSelected, setMinDepthSelected] = useState(0)
  const [maxDepthSelected, setMaxDepthSelected] = useState(500)
  const [filters, setFilters] = useState({})
  const [closeFilter, setCloseFilter] = useState(false)

  const [objectProductsCategories, setObjectProductsCategories] = useState([])
  const [styleTags, setStyleTags] = useState([])
  const [materialTags, setMaterialTags] = useState([])
  const [roomTags, setRoomTags] = useState([])
  const [colorTags, setColorTags] = useState([])
  const [brandsLexique, setBrandsLexique] = useState([])
  const [brandsPromoted, setBrandsPromoted] = useState([])
  const [buttonIds, setButtonIds] = useState([])

  const { t, i18n } = useTranslation('common');

  const countries = {
    'Austria': 'AT',
    'Belgium': 'BE',
    'Czech Republic': 'CZ',
    'Denmark': 'DK',
    'Deutschland': 'DE',
    'Finland': 'FI',
    'France': 'FR',
    'Italy': 'IT',
    'Japan': 'JP',
    'Netherlands': 'NL',
    'Norway': 'NO',
    'Poland': 'PL',
    'Portugal': 'PT',
    'Spain': 'ES',
    'Sweden': 'SE',
    'Switzerland': 'CH',
    'UK': 'GB',
    'Ukraine': 'UA',
    'United States': 'US'
  };

  const marks = [
    {
      value: minPrice,
      label: `${minPrice}`,
    }, ,
    {
      value: maxPrice,
      label: `${maxPrice}`,
    },
  ];
  const handlePriceChange = (event, values) => {
    setMinPriceSelected(values[0])
    setMaxPriceSelected(values[1])
  }
  const handleWidthChange = (event, values) => {
    setMinWidthSelected(values[0])
    setMaxWidthSelected(values[1])
  }
  const handleHeightChange = (event, values) => {
    setMinHeightSelected(values[0])
    setMaxHeightSelected(values[1])
  }
  const handleDepthChange = (event, values) => {
    setMinDepthSelected(values[0])
    setMaxDepthSelected(values[1])
  }
  const applyBudget = () => {
    setFilters({ ...filters, priceMax: maxPriceSelected, priceMin: minPriceSelected })
    !buttonIds.includes('price') && setButtonIds([...buttonIds, 'price'])
    setCloseFilter(true)
  }
  const applyObjectProductCategories = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['objectProductCategoryIds']) {
      filters['objectProductCategoryIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, objectProductCategoryIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyBrands = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['brandIds']) {
      filters['brandIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, brandIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyStyleTags = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['styleTagIds']) {
      filters['styleTagIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, styleTagIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyMaterialTag = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['materialTagIds']) {
      filters['materialTagIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, materialTagIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyRoomTag = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['roomTagIds']) {
      filters['roomTagIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, roomTagIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyColorTag = (id) => {
    if (buttonIds.includes(id)) {
      return
    }

    if (filters['colorTagIds']) {
      filters['colorTagIds'].push(id)
      setButtonIds([...buttonIds, id])
    } else {
      setFilters({ ...filters, colorTagIds: [id] })
      setButtonIds([...buttonIds, id])
    }
    setCloseFilter(true)
  }
  const applyCountry = (country) => {
    if (buttonIds.includes(country)) {
      return
    }

    if (filters['madeIn']) {
      filters['madeIn'].push(country)
      setButtonIds([...buttonIds, country])
    } else {
      setFilters({ ...filters, madeIn: [country] })
      setButtonIds([...buttonIds, country])
    }
    setCloseFilter(true)
  }
  const applySizes = () => {
    setFilters({
      ...filters, widthMin: minWidthSelected,
      widthMax: maxWidthSelected,
      heightMin: minHeightSelected,
      heightMax: maxHeightSelected,
      depthMin: minDepthSelected,
      depthMax: maxDepthSelected
    })
    !buttonIds.includes('sizes') && setButtonIds([...buttonIds, 'sizes'])
    setCloseFilter(true)
  }
  const get3DProducts = (with3D) => {
    setFilters({ ...filters, haveObjectProductModelBundle: with3D })
    !buttonIds.includes('haveObjectProductModelBundle') && setButtonIds([buttonIds, 'haveObjectProductModelBundle'])
    setCloseFilter(true)
  }

  useEffect(() => {
    if (closeFilter) {
      setCloseFilter(false)
    }
  }, [closeFilter])

  const onDeleteFilters = (object) => {
    if (!object.value) {
      let createFilters = filters
      for (const keyIdx in object.keys) {
        delete createFilters[object.keys[keyIdx]]
      }
      setFilters(createFilters)

      if (object.keys.includes('priceMin')) {
        setButtonIds(buttonIds.filter(e => e != 'price'))
      }
      if (object.keys.includes('widthMin')) {
        setButtonIds(buttonIds.filter(e => e != 'sizes'))
      }
      if (object.keys.includes('haveObjectProductModelBundle')) {
        setButtonIds(buttonIds.filter(e => e != 'haveObjectProductModelBundle'))
      }

    } else {
      if (filters[object.keys[0]].length > 1) {
        let createFilters = filters
        const filterIdx = createFilters[object.keys[0]].findIndex(a => a == object.value)
        createFilters[object.keys[0]].splice(filterIdx, 1)
        setFilters(createFilters)

      } else {
        let createFilters = filters
        delete createFilters[object.keys[0]]
        setFilters(createFilters)
      }
      setButtonIds(buttonIds.filter(e => e != object.value))
    }
  }

  const filterButtons = [
    {
      id: 'price',
      icon: 'local_atm',
      label: 'Budget',
      width: window.innerWidth > 756 ? '45%' : '80%',
      height: window.innerWidth > 756 ? 'auto' : '22%',
      content:
        <div className="budgetFilter">
          <Slider
            defaultValue={[minPrice, maxPrice]}
            min={minPrice}
            max={maxPrice}
            valueLabelDisplay="on"
            marks={marks}
            aria-labelledby="non-linear-slider"
            onChange={handlePriceChange}
          />
          <span className="applyFilters">
            <Button onClick={() => setCloseFilter(true)} variant="outlined">
              {t('filters.clear')}
            </Button>
            <Button onClick={() => applyBudget()} variant="outlined">
              {t('filters.apply')}
            </Button>
          </span>
        </div>
    },
    {
      id: 'object',
      icon: 'chair',
      label: 'Object',
      width: '80%',
      height: '60%',
      content:
        <div className="objectProductCategories">
          {objectProductsCategories.length > 0 &&
            objectProductsCategories.map(c =>
              <div>
                <h4>{i18n.language == 'fr' ? c.slug.charAt(0).toUpperCase() + c.slug.slice(1) : c.name}</h4>
                {c.childs.map(s => 
                  <div className="objectProductSubcategory" onClick={() => applyObjectProductCategories(s.id)}>
                    {i18n.language == 'fr' ? s.slug.charAt(0).toUpperCase() + s.slug.slice(1) : s.name}
                  </div>
                )}
              </div>)
          }
        </div>
    },
    {
      id: 'brand',
      icon: 'sell',
      label: 'Brand',
      width: '70%',
      height: '60%',
      content: <div className="brandsWrapper">
        <div className="brandsPromoted">
          {brandsPromoted.length > 0 &&
            brandsPromoted.map(p =>
              p.logo && <img src={p.logo.url} width="100" onClick={() => applyBrands(p.id)}></img>
            )
          }
        </div>
        <div className="brandsLexique">
          {Object.keys(brandsLexique).sort().map((key, idx) =>
            <div className="brandLexique">
              <h4>{key.toUpperCase()}</h4>
              {brandsLexique[key].map(i =>
                <div className="brandLexiqueName" onClick={() => applyBrands(i.id)}>{i.name}</div>
              )
              }
            </div>
          )
          }
        </div>
      </div>
    },
    {
      id: 'style',
      icon: 'style',
      label: 'Style',
      width: window.innerWidth > 756 ? '60%': '90%',
      height: 'auto',
      content: <div className="styleTags">
        {styleTags.length > 0 &&
          styleTags.map(t => <div className="styleTag" onClick={() => applyStyleTags(t.id)}>
            <img src={t.picture.url} width="170" />
            <span>{i18n.language == 'fr' ? t.slug.charAt(0).toUpperCase() + t.slug.slice(1) : t.name}</span>
          </div>)
        }
      </div>
    },
    {
      id: 'material',
      icon: 'texture',
      label: 'Materials',
      width: 'auto',
      height: 'auto',
      content: <div className="materialTags">
        {materialTags.length > 0 &&
          materialTags.map(m => <div className="materialTag" onClick={() => applyMaterialTag(m.id)}>
            <img width="30" src={require(`../../src/assets/materials/${m.name}.png`)}></img>
            {i18n.language == 'fr' ? m.slug.charAt(0).toUpperCase() + m.slug.slice(1) : m.name}
          </div>
          )
        }
      </div>
    },
    {
      id: 'room',
      icon: 'living',
      label: 'Room',
      width: window.innerWidth > 756 ? '60%': '90%',
      height: 'auto',
      content: <div className="roomTags">
        {roomTags.length > 0 &&
          roomTags.map(r => <div className="roomTag" onClick={() => applyRoomTag(r.id)}>
            <img src={r.picture.url} width="170" />
            <span>{i18n.language == 'fr' ? r.slug.charAt(0).toUpperCase() + r.slug.slice(1) : r.name}</span>
          </div>

          )

        }
      </div>
    },
    {
      id: 'color',
      icon: 'colorize',
      label: 'Color',
      width: '40%',
      height: 'auto',
      content: <div className="colorTags">
        {colorTags.length > 0 &&
          colorTags.map(c =>
            <div className="colorTag" onClick={() => applyColorTag(c.id)}>
              <div className="buttonColor">
                <div className="colorAvatar" style={{ backgroundColor: `${c.hexaColor}` }}></div>
              </div>
              <h6>{i18n.language == 'fr' ? c.slug.charAt(0).toUpperCase() + c.slug.slice(1) : c.name}</h6>
            </div>)
        }
      </div >
    },
    {
      id: 'origin',
      icon: 'location_on',
      label: 'Origin',
      height: 'auto',
      content: <div className="origins">
        {Object.keys(countries).map(country =>
            <div className="origin" onClick={() => applyCountry(countries[country])}>
              <Flag code={countries[country]} height={16} width={30} />
              <span className='origin-country'>{t(`filtersOrigin.${country.toLowerCase()}`)}</span>
            </div>)}
      </div>
    },
    {
      id: '3D',
      icon: 'view_in_ar',
      label: '3D',
      width: 'auto',
      height: 'auto',
      content: <div className="3Ds">
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button onClick={() => get3DProducts(true)}>{t('filters.with3d')}</Button>
          <Button onClick={() => get3DProducts(false)}>{t('filters.without3d')}</Button>
        </ButtonGroup>
      </div>
    },
    {
      id: 'size',
      icon: 'straighten',
      label: 'Size',
      width: window.innerWidth > 756 ? '30%': '60%',
      height: 'auto',
      content: <div className="sizes">
        <div className="size">
          <p>Width:</p>
          <Slider
            defaultValue={[0, 500]}
            min={0}
            max={500}
            valueLabelDisplay="on"
            aria-labelledby="non-linear-slider"
            onChange={handleWidthChange}
          />
        </div>
        <div className="size">
          <p>Height:</p>
          <Slider
            defaultValue={[0, 500]}
            min={0}
            max={500}
            valueLabelDisplay="on"
            aria-labelledby="non-linear-slider"
            onChange={handleHeightChange}
          />
        </div>
        <div className="size">
          <p>Depth:</p>
          <Slider
            defaultValue={[0, 500]}
            min={0}
            max={500}
            valueLabelDisplay="on"
            aria-labelledby="non-linear-slider"
            onChange={handleDepthChange}
          />
        </div>
        <span className="applyFilters">
          <Button onClick={() => setCloseFilter(true)} variant="outlined">
            {t('filters.clear')}
            </Button>
          <Button onClick={() => applySizes()} variant="outlined">
            {t('filters.apply')}
            </Button>
        </span>
      </div>
    },
  ];

  useEffect(() => {
    //fetch object product categories
    fetch('https://api.addsome.io/object-product-categories?' + new URLSearchParams({
      skip: 0,
      order: JSON.stringify({ promoted: "DESC", createdAt: "DESC" }),
      query: '',
    }), {
      method: 'GET',
      headers: {
        'Connection': 'keep-alive',
        'Access-Control-Allow-Origin': '*',
        'Sec-Fetch-Mode': 'cors',
        'Accept': 'application/json, text/plain, */*'
      },
    })
      .then(response => response.json())
      .then(data => {
        // console.log("Object-products", data)
        setObjectProductsCategories(data)
      })
      .catch((error) => {
        console.log("Error", error)
      });
    //fetch product material tag
    fetch('https://api.addsome.io/product-material-tags?',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("MaterialTags", data)
        setMaterialTags(data)
      })
      .catch((error) => {
        console.log("Error", error)
      });
    //fetch style tag
    fetch('https://api.addsome.io/style-tags?',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Style tags", data.data)
        setStyleTags(data.data)
      })
      .catch((error) => {
        console.log("Error", error)
      });
    //fetch color tag
    fetch('https://api.addsome.io/color-tags?',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Color tags", data)
        setColorTags(data)
      })
      .catch((error) => {
        console.log("Error", error)
      })
    //fetch room tag
    fetch('https://api.addsome.io/room-tags?',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Room tags", data)
        setRoomTags(data)
      })
      .catch((error) => {
        console.log("Error", error)
      })
    //fetch brand promoted
    fetch('https://api.addsome.io/brands/landing?' + new URLSearchParams({
      skip: 0,
      take: 8,
      order: JSON.stringify({ promoted: "DESC" }),
      promoted: true,
      query: '',
    }),
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Promoted", data)
        setBrandsPromoted(data.data)
      })
      .catch((error) => {
        console.log("Error", error)
      })
    //fetch brand lexique
    fetch('https://api.addsome.io/brands/landing/lexique',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Lexique", data)
        setBrandsLexique(data)
      })
      .catch((error) => {
        console.log("Error", error)
      })
    //fetch prices
    fetch('https://api.addsome.io/products/prices',
      {
        method: 'GET',
        headers: {
          'Connection': 'keep-alive',
          'Access-Control-Allow-Origin': '*',
          'Sec-Fetch-Mode': 'cors',
          'Accept': 'application/json, text/plain, */*'
        },
      })
      .then(response => response.json())
      .then(data => {
        // console.log("Products prices", data)
        setMinPrice(data.priceMin)
        setMaxPrice(data.priceMax)
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }, [])

  return {
    filterButtons,
    filters,
    closeFilter,
    objectProductsCategories,
    styleTags,
    materialTags,
    roomTags,
    colorTags,
    countries,
    brandsLexique,
    buttonIds,
    onDeleteFilters,
  };
}